* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --black: #000000;
    --white: #ffffff;
    --border-dark: #0000004d;
    --text-faded: #aaa9a9e5;
    --body-bg: #fff;
}

html,
body {
    height: 100%;
    max-height: -webkit-fill-available;
    min-height: 100%;
    background-color: var(--body-bg);
    color: var(--black);
    font-family: 'Open Sans', sans-serif;
    transition: background 0.3s linear;
    overflow: hidden;
}

.dark-mode {
    --black: #fff;
    --white: #000;
    --border-dark: #9c97974d;
    --text-faded: #757575be;
    --body-bg: #110f0f;
}

#root {
    height: 100%;
    position: relative;
}

p,
.input_type_,
.input_type_ input {
    font-size: 1.2rem;
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 400;
    line-height: 1;
}

.btn {
    cursor: pointer;
    text-align: center;
    padding: 6px 11px;
    font-size: 1rem;
    background: var(--white);
    border-radius: 6px;
    border: 1px solid var(--border-dark);
    color: var(--black);
}

.btn:disabled {
    opacity: .4;
}

.cs-input {
    background-color: var(--body-bg);
    color: var(--black);
    border: none;
    outline: none;
    padding: 7px 7px;
    border-radius: 4px;
    border: 1px solid var(--border-dark);
    max-width: 100%;
    margin-right: 10px;
    /* width: 80%; */
}


/* TOOLTIP */

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted var(--white);
}

.tooltip .tooltiptext {
    visibility: hidden;
    min-width: 170px;
    background-color: var(--white);
    color: var(--black);
    text-align: left;
    font-size: 0.8rem;
    top: -10px;
    border-radius: 6px;
    padding: 8px 10px;
    text-transform: uppercase;
    left: 32px;
    position: absolute;
    z-index: 1;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.212);
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.tooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent var(--white) transparent transparent;
}


/* WELCOME SCREEN */

.welcome_box {
    margin: auto;
    max-width: 440px;
    width: 100%;
    border-radius: 6px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.247);
    transition: background 0.3s linear;
    max-height: calc(100% - 20px);
    overflow: auto;
}

.dark-mode .welcome_box {
    box-shadow: 0 0 15px #4d4b4b60;
}

.welcome_box header {
    padding: 7px 11px;
    border-bottom: 1px solid var(--border-dark);
    margin-bottom: 12px;
}

.welcome_box h1 {
    padding: 11px 23px;
    text-align: center;
    font-size: 1.8rem;
}

.flow {
    padding: 7px 17px;
    text-align: center;
}

.flow .flow_1 {
    display: flex;
    width: 100%;
    text-align: left;
    align-items: center;
}

.flow_1 label {
    font-size: 1.3rem;
}

.flow input {
    margin-right: 10px;
}

.flow_input {
    width: 50px;
    border: 1px solid var(--black);
    border-radius: 3px;
}

.welcome_box button {
    margin: 12px auto;
    padding: 8px 19px;
}

.i_icon {
    margin-left: 10px;
    cursor: pointer;
    font-size: 20px;
}

.flow_writing_section {
    width: 100%;
    height: 100%;
    overflow: hidden;
    max-height: -webkit-fill-available;
    background-color: var(--white);
    color: var(--black);
    position: relative;
}

.editor_wrapper {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-end;
    align-items: flex-end;
    width: 70%;
    max-width: 1000px;
    height: 50%;
    /* font-size: 2.1rem; */
    overflow: hidden;
}

.renderer_para {
    display: block;
    width: 100%;
    font-size: 1.2rem;
}

.dark-mode .renderer_para {
    color: var(--black);
}

.gray_text {
    color: var(--text-faded) !important;
}

.renderer_para:last-of-type {
    width: auto;
    display: inline;
}

.input_type_ {
    border: 0px;
    margin: 0px;
    padding: 0px;
    outline: none;
    caret-color: var(--black);
    background-color: transparent;
}

.dark-mode .input_type_ * {
    color: var(--dark);
}

.dark-mode .input_type_ input {
    color: var(--black);
}

.input_type_ * {
    background-color: transparent;
}

.input_type_:focus {
    border: 0px;
    margin: 0px;
    padding: 0px;
    outline: none;
}

.main_wrapper {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    max-height: -webkit-fill-available;
    min-height: 100%;
}


/* TOP HEADER  */

.top_header {
    width: 100%;
    padding: 20px;
    position: sticky;
    /* position: f; */
    position: -webkit-sticky;
    z-index: 10;
    /* left: 0; */
    top: 0px;
    display: flex;
    justify-content: flex-end;
}

.fade_aniamtion {
    animation: fadeout 7s linear;
}

.fade_aniamtion_for {
    animation: fadeout1 1s linear;
}

@keyframes fadeout {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0.1;
    }
}

@keyframes fadeout1 {
    0% {
        opacity: 0.2;
    }
    100% {
        opacity: 0.1;
    }
}


/* MODAL */

.modal_overlay {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: 100%;
    z-index: 9;
    background-color: #1b1b1b38;
}

.dark-mode .modal_overlay {
    background-color: #3636364b;
}

.dark-mode .modal {
    box-shadow: 0px 0px 14px #6666662c;
}

.modal {
    max-width: 500px;
    padding: 30px 85px;
    display: flex;
    background-color: var(--white);
    border-radius: 6px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: #2f2f3033 0px 8px 24px;
    max-height: calc(100vh - 60px);
}

.modal p {
    margin: 20px 0;
}

.modal div {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 500px) {
    .welcome_box {
        width: 90%;
    }
    .welcome_box h1 {
        font-size: 1.4rem;
    }
    .flow_1 label {
        font-size: 1rem;
    }
    .tooltip .tooltiptext {
        font-size: 0.56rem;
        min-width: 140px;
        top: -6px;
    }
    .flow_writing_section {
        /* height: 60%; */
    }
    .editor_wrapper {
        width: 92%;
        /* height: 82%; */
    }
    .modal {
        max-width: 90%;
        padding: 30px;
    }
}

@media screen and (max-width: 390px) {
    .tooltip .tooltiptext {
        font-size: 0.46rem;
        min-width: 73px;
    }
}

@media screen and (max-width: 360px) {
    .welcome_box h1 {
        font-size: 1.2rem;
    }
    .modal {
        width: 90%;
        max-width: 90%;
        padding: 20px 10px;
    }
    .modal form {
        max-width: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .modal form input {
        flex: 1;
    }
}